import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Rich text rendering
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { ContentContainer } from "../components/Theme"
import { renderOption } from "../utils/contentful-options"
// import { renderOption } from "../utils/contentful-options"

const Title = styled.h1`
  font-weight: normal;
`

const GrandeHotelFantasiaEntryContentful = ({ data, location }) => {
  const post = data.contentfulGrandeHotelFantasiaEntry
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.title} description={post.subtitle} />
      <ContentContainer>
        <header>
          <Title data-sal="fade" data-sal-easing="ease">
            {post.title}
          </Title>
        </header>
        <section>
          {documentToReactComponents(post.content.json, renderOption)}
        </section>
      </ContentContainer>
    </Layout>
  )
}

export default GrandeHotelFantasiaEntryContentful

export const pageQuery = graphql`
  query ContentfulGrandeHotelFantasiaEntryBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulGrandeHotelFantasiaEntry(slug: { eq: $slug }) {
      title
      subtitle
      content {
        json
        content
      }
    }
  }
`
